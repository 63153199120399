import Criteria from "core-criteria/Criteria";
import QueryByStache from "core-criteria/queryBy/QueryByCriteria.stache";
import UnitFormat from "core-cmp/format/UnitFormat";

/**
 * @class ThmFilterCriteria
 */
let ThmFilterCriteria = Criteria.extend({}, {

  format:    "string",
  name:      "string",
  maxLength: "number",
  type:      'text',
  operator:  "string",
  i18nPrefix: "string",

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;

    if (!me.stache) {
      me.stache = QueryByStache;
    }

    me.i18nPrefix = me.i18nPrefix || 'thm.criteria.ThmFilterCriteria.';
    me._super(config);

    if (config.type) {
      me.type = config.type;
    }

    me.criteriaFormModel = {};

    me.criteriaFormModel[me.name] = me.format === 'length' ? null : '';

  },

  /**
   * @override
   * Au moment de l'envoie de la requete, il faut determiner comment la construire
   */
  formatModelToUDA: function (paramsUda) {
    let me       = this,
        params   = me.criteriaFormModel,
        operator = me.operator,
        value = params[me.name];

    if (value && (typeof value === 'number' || value.length > 0)) {
      if (!paramsUda['filter']) {
        paramsUda['filter'] = '{}';
      }

      let test = paramsUda['filter'].split('}');
      if (test[0] !== '{') {
        test[0] += ',';
      }

      test[0] += me.name + ':';
      test[0] += operator === "*" ? "\"" + operator + value + operator + "\"" : "\"" + operator + value + "\"";

      paramsUda['filter'] = test[0] + '}';
    }

    return paramsUda;
  },


  /**
   * @override
   */
  setParams: function (params) {
    let me = this;

    if (params[me.name] && params[me.name] !== "") {
      me.criteriaFormModel[me.name] = params[me.name];
    }
  }

});

export default ThmFilterCriteria;
