<div id="{{id}}" class="L-fit L-panel">
  <ui-page-header {cmp-config}="headerConfig">
    <field-state {(field)}="tabs" options="tabsOptions" options-sort="false" options-col="2"
                 class="tab"></field-state>
  </ui-page-header>
  <field-item item="dataModel" class="L-panel-content">

    {{!---------------- TAB General ---------------------}}

    <div id="{{id}}-definition" class="{{#is tabs "definition"}}{{else}}hidden{{/is}}">

      {{!---------------- TAB General / Account ---------------------}}

      <div class="header-group icon-left icon-m ui-icon-help">
        <field-like label="{{msg "account"}}">
          <div style="float: right" cls-action="resetPassword"
               class="btn ui-icon-key icon-l icon-left " {{hidden
            cannotChangePassword}}>{{msg "changePassword"}}</div>
        </field-like>
      </div>
      <div id="{{id}}-account" class="inlineGroup fields">

        {{#if ldap}}
          <field-select name="login" {options}="ldapUsers" options-value="name"
                        use-i18n="false" col="6 12"></field-select>

        {{else}}
          <field-text name="login" max-length="36" required="true"
                      col="{{#if showClient}}6 12{{else}}12{{/if}}"></field-text>
        {{/if}}

        {{#if showClient}}
          <uda-select of="customers" order-by="company" name="customerId" col="6 12"
                      {enabled}="_cmp.creation"></uda-select>
        {{/if}}

        {{#unless ldap}}
          <field-password name="passwordCreation" col="6 12" {max-length}="passwordCmpConfig.checkPasswordMaxSize" {visible}="_cmp.creation"
                          {cmp-config}="../passwordCmpConfig"></field-password>
          <field-password name="passwordCreationConfirm" col="6 12" {max-length}="passwordCmpConfig.checkPasswordMaxSize"
                          {visible}="_cmp.creation"></field-password>
        {{/unless}}

        <field-select name="roleId" {options}="roleOptions" options-value="id" options-text="name"
                      col="12"></field-select>


      </div>

      {{!---------------- TAB General / Contact ---------------------}}

      <div class="header-group icon-left icon-m ui-icon-user">
        <field-like label="{{msg "contact"}}">
          <div style="float: right" cls-action="sendMessage"
               class="btn ui-icon-mail icon-l icon-left " {{hidden
            _cmp.creation}}>{{msg "sendMessage"}}</div>
        </field-like>
      </div>
      <div id="{{id}}-contact" class="inlineGroup fields">
        <field-text name="numberId" max-length="10" col="12 12"></field-text>
        <field-text name="email" max-length="100" format="email" col="12 12"></field-text>
        <field-text name="name" max-length="60" col="6 12"></field-text>
        <field-text name="firstName" max-length="60" col="6 12"></field-text>
        <field-text name="phone" max-length="20" col="6 12"></field-text>
        <field-text name="fax" max-length="20" col="6 12"></field-text>
        <field-text name="office" max-length="40" col="6 12"></field-text>
        <field-text name="agency" max-length="10" col="6 12"></field-text>
        <field-text name="organisation" max-length="75" col="6 12"></field-text>
        <field-text name="job" max-length="60" col="6 12"></field-text>
        <field-textarea name="description" max-length="240" label-align="top" col="12"></field-textarea>

      </div>


    </div>

    <!---------------- TAB Restriction --------------------->

    <div id="{{id}}-definition" class="{{#is tabs "restriction"}}{{else}}hidden{{/is}}">
      {{!---------------- TAB General / Account validity ---------------------}}
      <div class="header-group icon-left icon-m ui-icon-licence">
        <h2>{{msg "accountValidity"}}</h2>
      </div>
      <div id="{{id}}-licence" class="inlineGroup fields">
        {{#if showDeactivation}}
          <field-switch label-key="locked" name="active"></field-switch>
          <field-like id="activeInfo" {hidden}="dataModel.active">{{msg
            "accountDeactivatedInformation"}}</field-like>
        {{/if}}
        <field-date label-key="from" name="licenceStartDate" format="dateTime" min="0"
                    col="6 12"></field-date>
        <field-date label-key="to" name="licenceExpireDate" format="dateTime" min="0" max="1825"
                    col="6 12"></field-date>
        <field-text name="authorizedIps" format="ip" col="12 12"></field-text>
      </div>


      <div class="header-group icon-left icon-m ui-icon-lock">
        <h2>{{msg 'restrictionOnData'}}</h2>
      </div>
      <div class="inlineGroup fields">
        <field-checkbox name="restriction.geo" label-key="_RestrictionZone"></field-checkbox>
      </div>
      <uda-list of="zones"
                name="authorizedZones"
                label-key="_RestrictionZone"
                {update-for}="dataModel.customerId"
                update-function="findForAlertDefinitions"
                class="inlineGroup"
                {visible}="dataModel.restriction.geo"></uda-list>
      <div class="inlineGroup fields">
        <field-checkbox name="restriction.period" label-key="restrictionDate"></field-checkbox>

        <field-hcombo {(field)}="dateParams" label="" {options}="dateParamsOptions"
                      {visible}="dataModel.restriction.period"></field-hcombo>

        {{! Critère last days }}

        <field-text name="restriction.validDays" format="pnum" min="0" col="6 6"
                    label-align="right" {visible}="dateParamsLastDays"></field-text>

        {{! Critère between }}
        <field-date name="restriction.validStartDate" label-key="_From" format="dateTime" col="6 12"
                    class="l30-f70"
                    {visible}="dateParamsBetween"></field-date>
        <field-date name="restriction.validEndDate" label-key="_To" format="dateTime" col="6 12" class="l30-f70"
                    {visible}="dateParamsBetween"></field-date>


        <field-hcombo label="" {(field)}="restrictionMobileFlag"
                      {options}="restrictionMobileOptions"></field-hcombo>


      </div>
      <uda-list of="mobiles"
                name="authorizedMobiles"
                label-key="_Mobiles"
                {update-for}="dataModel.customerId"
                update-function="filterByCustomerId"
                class="inlineGroup"
                {visible}="dataModel.restriction.mobile"></uda-list>
      <uda-list of="fleets"
                name="authorizedFleets"
                label-key="_Fleets"
                class="inlineGroup"
                {visible}="dataModel.restriction.fleet"></uda-list>
      <uda-list of="userGroups"
                name="userGroups"
                (beforeitemdelete)="onBeforeDeleteUserGroup"
                class="inlineGroup"
                {visible}="showUserGroup"></uda-list>
      <field-item item="criteriaFormModel">
        <div class="inlineGroup">
          <field-hcombo {(field)}="restrictionSourceFlag" label=""
                        options="restrictionSourceOptions"></field-hcombo>
          <field-checkboxes name="authorizedSources" label="" options="sources"
                            format="array" {visible}="dataModel.restriction.source"></field-checkboxes>
        </div>
      </field-item>

    </div>
  </field-item>

  <ui-submit-cancel class="L-panel-footer" {status}="saveStatus" {visible}="allowEdit"></ui-submit-cancel>
</div>
