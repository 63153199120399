import $ from "core-cmp/cmp-jquery";
import Page from "core-cmp/Page";
import { APP } from "core-uda/model/Resource";
import { VERSION } from "thm/ThmConstants";
import ParametersPage from "core-uda/ui/settings/ParametersPage";
import PreferencesPage from "core-uda/ui/settings/PreferencesPage";
import UserInfosPage from "core-uda/ui/settings/UserInfosPage";
import UnitConversionPage from "core-uda/ui/settings/UnitConversionPage";
import MtccReportPage from "thm/ui/common/mtccreport/MtccReportPage";
import GeneralAlertBroadcastPage from "thm/ui/common/generalAlertBroadcast/GeneralAlertBroadcastPage"
import { RIGHTS, DATA_AUTHORIZED } from "core-uda/Rights";
import ImportPositionPopup from "core-mobile/ui/ImportPositionPopup";
import ImportErsMessagePopup from "thm/ui/common/ersManualImport/ImportErsMessagePopup";
import MobileImportPopup from "thm/ui/common/mobileImport/MobileImportPopup";
import ReportTemplateUploadPage from "thm/ui/common/settings/ReportTemplateUploadPage";
import SettingsPageTplStache from "thm/ui/common/settings/SettingsPageTpl.stache";
import "thm/ui/common/settings/SettingsPage.css";

/**
 * Settings.
 * @class SettingsPage.
 */
let SettingsPage = Page.extend({
  id: 'settingsPage',
  routerType: 'tab',
  template: SettingsPageTplStache,
  i18nPrefix: ["thm.ui.common.settings.SettingsPage."],
  autoBindActions: true,

  activateButton: function (ev) {
    let me = this;

    me.$el.find('.active').removeClass('active');
    $(ev.currentTarget).toggleClass('active', true);
  },

  openPanel: function (panelConfig, ev) {
    let me = this;

    me.activateButton(ev);

    me.closeSubPages(true).done(function (closedPage) {
      panelConfig.skipAnimation = !!closedPage;
      Page.openDataPanel(panelConfig);
    });
  },

  onOpenUserGuide: function (ev) {
    let url = window.location.origin + RIGHTS("map.userGuide", "");
    window.open(url);
  },

  onPreferences: function (ev) {
    let me = this;
    me._openPage(ev, PreferencesPage);
  },

  onParameters: function (ev) {
    let me = this;
    me._openPage(ev, ParametersPage);
  },

  onReportTemplate: function (ev) {
    let me = this;
    me._openPage(ev, ReportTemplateUploadPage);
  },

  onUserInfos: function (ev) {
    let me = this;
    me._openPage(ev, UserInfosPage);
  },

  onUnitConversion: function (ev) {
    let me = this;
    me._openPage(ev, UnitConversionPage);
  },

  onImportPositions: function (ev) {
    let me = this;

    ImportPositionPopup.openSingleton({});
  },

  onGeneralAlertBroadcast: function (ev) {
    let me = this;

    me._openPage(ev, GeneralAlertBroadcastPage);
  },

  onImportErsMessages: function (ev) {
    let me = this;

    ImportErsMessagePopup.openSingleton({});
  },

  onImportMobiles: function (ev) {
    let me = this;

    MobileImportPopup.openSingleton({});
  },

  onMtccReport: function (ev) {
    let me = this;
    me._openPage(ev, MtccReportPage);
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr('mtccReport', RIGHTS('mtccreport') && RIGHTS('umv.mtccreport'));
    me.viewModel.attr('build', VERSION);
    me.viewModel.attr('udaBuild', APP().capabilities.enums.softwareVersion);
    me.viewModel.attr('userGuide', !!RIGHTS('map.userGuide', false));
    me.viewModel.attr('userInfos', APP().getCapabilities('user', 'changePassword') && RIGHTS("userInformation"));
    me.viewModel.attr('parameters', DATA_AUTHORIZED('parameters'));

    // Initialisation à l acréation
    me.viewModel.attr('importPositions', !!DATA_AUTHORIZED('position.create'));
    me.viewModel.attr('importErsMessages', !!DATA_AUTHORIZED('manualImport.create'));
    me.viewModel.attr('importMobiles', !!RIGHTS('data.importMobiles') && RIGHTS('data.importMobiles').authorized);
  },

  /**
   * @override
   */
  doAfterOpen: function () {
    let me = this;
    me._super();

    // Ouverture automatique du premier onglet en mode popup
    if (!me.isOpenAs("page") && me.context.showSettingsPage) {
      me.$findByAction("preferences").click();
    }
  },

  // private
  _openPage: function (ev, pageClass) {
    let me = this;

    me.closeSubPages(true).done((closedPage) => {
      me.activateButton(ev);
      pageClass.openPage(null, !!closedPage);
    });

  }

});

export default SettingsPage;
