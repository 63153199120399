<div id="{{id}}" class="L-fit L-panel">

    <ui-loading anchor="center" class="{{#unless loadingScreen}}hidden{{/unless}}"></ui-loading>

    <div class="L-panel-content {{#if loadingScreen}}hidden{{/if}}">
        <ui-page-header {cmp-config}="headerConfig"></ui-page-header>

        <field-item item="dataModel" class="L-panel-content {{#if fshlogOrigin}}hidden{{/if}}">
            <div class="header-group icon-left icon-m ui-icon-info">
                <h2>{{msg 'info'}}</h2>
            </div>

            <div class="inlineGroup fields">
                <field-text col="6 12" class="icon-l icon-left ui-icon-calendar" name="startDate"
                            {disabled}="disabled"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-calendar" name="endDate" {disabled}="disabled"
                            {visible}="visibles.endDate"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-calendar" name="endPlannedDate" {disabled}="disabled"
                            {visible}="visibles.endPlannedDate"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-calendar" name="startFishingDate" {disabled}="disabled"
                            {visible}="visibles.startFishingDate"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-calendar" name="landingTime" {disabled}="disabled"
                            {visible}="visibles.landingTime"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-position" name="position" {disabled}="disabled" format="position"
                            {visible}="visibles.position"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-measureStart" name="positionStart" {disabled}="disabled"
                            {visible}="visibles.positionStart"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-measureEnd" name="positionEnd" {disabled}="disabled"
                            {visible}="visibles.positionEnd"></field-text>
                <field-text col="6 12" name="relocatedTo" {disabled}="disabled" {visible}="visibles.relocatedTo"
                            class="icon-l icon-left ui-icon-resizeH"></field-text>
                <field-text col="6 12" class="icon-l icon-left ui-icon-zones" name="zoneFullName" {disabled}="disabled"
                            {visible}="visibles.zoneFullName"></field-text>
                <field-text col="6 12" name="portName" {disabled}="disabled" {visible}="visibles.portName"
                            class="icon-l icon-left ui-icon-port"></field-text>
                <field-text col="6 12" name="portReference" {disabled}="disabled" {visible}="visibles.portReference"
                            class="icon-l icon-left ui-icon-portLabel"></field-text>
                <field-text col="6 12" name="senderKind" {disabled}="disabled" {visible}="visibles.senderKind"></field-text>
                <field-text col="6 12" name="mobileCaptainName" {disabled}="disabled" {visible}="visibles.mobileCaptainName"
                            class="icon-l icon-left ui-icon-boatAnchor"></field-text>
                <field-text col="6 12" name="hole" {disabled}="disabled" {visible}="visibles.hole"
                            class="icon-l icon-left ui-icon-center"></field-text>
                <field-text col="6 12" name="purposeCall" {disabled}="disabled" {visible}="visibles.purposeCall"
                            class="icon-l icon-left ui-icon-next"></field-text>
                <field-text col="6 12" name="reasonReturn" {disabled}="disabled" {visible}="visibles.reasonReturn"
                            class="icon-l icon-left ui-icon-next"></field-text>
                <field-text col="6 12" name="anticipatedActivity" {disabled}="disabled"
                            {visible}="visibles.anticipatedActivity"></field-text>
                <field-text col="6 12" name="targetSpecies" {disabled}="disabled" {visible}="visibles.targetSpecies"
                            class="icon-l icon-left ui-icon-fish"></field-text>
                <field-text col="6 12" name="inspectorName" {disabled}="disabled" {visible}="visibles.inspectorName"
                            class="icon-l icon-left ui-icon-search"></field-text>
                <field-text col="6 12" name="inspectorFlagCode" {disabled}="disabled" class="icon-l icon-left ui-icon-flag"
                            {visible}="visibles.inspectorFlagCode"></field-text>
                <field-text col="6 12" name="countryCode" {disabled}="disabled" {visible}="visibles.countryCode"
                            class="icon-l icon-left ui-icon-flag"></field-text>
                <field-text col="6 12" name="totalWeight" {disabled}="disabled" {visible}="visibles.totalWeight"
                            class="icon-l icon-left ui-icon-weight"></field-text>
                <field-text col="6 12" name="containerNumber" {disabled}="disabled" {visible}="visibles.containerNumber"
                            class="icon-l icon-left ui-icon-layers"></field-text>
                <field-text col="6 12" name="statisticZone" {disabled}="disabled" {visible}="visibles.statisticZone"
                            class="icon-l icon-left ui-icon-zones"></field-text>
                <field-text col="6 12" name="loch" {disabled}="disabled" {visible}="visibles.loch"
                            class="icon-l icon-left"></field-text>
                <field-textarea name="reason" {disabled}="disabled" {visible}="visibles.reason"
                                class="icon-l icon-left ui-icon-annotation"></field-textarea>
            </div>

            <div class="header-group icon-left icon-m ui-icon-user {{#unless groups.crew}}hidden{{/unless}}">
                <h2>{{msg 'crew'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.crew}}hidden{{/unless}}">
                <field-text col="6 12" class="l70-f30" name="nationalCrewNumber" {disabled}="disabled" {visible}="visibles.nationalCrewNumber"></field-text>
                <field-text col="6 12" class="l70-f30" name="foreignCrewNumber" {disabled}="disabled" {visible}="visibles.foreignCrewNumber"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-company {{#unless groups.dealership}}hidden{{/unless}}">
                <h2>{{msg 'dealership'}}</h2>
            </div>
            <div class="fields {{#unless groups.dealership}}hidden{{/unless}}">
                <div class="inlineGroup ">
                    <field-text col="6 12" name="dealershipName" {disabled}="disabled" {visible}="visibles.dealershipName"></field-text>
                    <field-text col="6 12" name="dealershipFishingZone" {disabled}="disabled" {visible}="visibles.dealershipFishingZone"></field-text>
                    <field-text col="6 12" name="dealershipType" {disabled}="disabled" {visible}="visibles.dealershipType"></field-text>
                </div>
                <div class="inlineGroup ">
                    <field-text col="6 12" name="dealershipSignatureDate" {disabled}="disabled" {visible}="visibles.dealershipSignatureDate"></field-text>
                    <field-text col="6 12" name="dealershipExpirationDate" {disabled}="disabled" {visible}="visibles.dealershipExpirationDate"></field-text>
                    <field-text name="dealershipValidityYears" {disabled}="disabled" {visible}="visibles.dealershipValidityYears"></field-text>
                </div>
            </div>

            <div class="header-group icon-left icon-m ui-icon-vessel {{#unless groups.register}}hidden{{/unless}}">
                <h2>{{msg 'register'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.register}}hidden{{/unless}}">
                <field-text col="6 12" name="registerName" {disabled}="disabled" {visible}="visibles.registerName"></field-text>
                <field-text col="6 12" name="registerIccat" {disabled}="disabled"
                            {visible}="visibles.registerIccat"></field-text>
                <field-text col="6 12" name="cagesNumber" {disabled}="disabled" {visible}="visibles.cagesNumber"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-export {{#unless groups.donnor}}hidden{{/unless}}">
                <h2>{{msg 'giver'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.donnor}}hidden{{/unless}}">
                <field-text col="6 12" name="mobileDonnorName" {disabled}="disabled"
                            {visible}="visibles.mobileDonnorName"></field-text>
                <field-text col="6 12" name="mobileDonnorCallSign" {disabled}="disabled"
                            {visible}="visibles.mobileDonnorCallSign"></field-text>
                <field-text col="6 12" name="mobileDonnorFlagCountryName" {disabled}="disabled"
                            {visible}="visibles.mobileDonnorFlagCountryName"></field-text>
                <field-text col="6 12" name="mobileDonnorRef" {disabled}="disabled"
                            {visible}="visibles.mobileDonnorRef"></field-text>
                <field-text col="6 12" name="mobileDonnorRegistry" {disabled}="disabled"
                            {visible}="visibles.mobileDonnorRegistry"></field-text>
                <field-text name="mobileDonnorImo" {disabled}="disabled" {visible}="visibles.mobileDonnorImo"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-import {{#unless groups.reciever}}hidden{{/unless}}">
                <h2>{{msg 'receiver'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.reciever}}hidden{{/unless}}">
                <field-text col="6 12" name="mobileRecieverName" {disabled}="disabled"
                            {visible}="visibles.mobileRecieverName"></field-text>
                <field-text col="6 12" name="mobileRecieverCallSign" {disabled}="disabled"
                            {visible}="visibles.mobileRecieverCallSign"></field-text>
                <field-text col="6 12" name="mobileRecieverFlagCountryName" {disabled}="disabled"
                            {visible}="visibles.mobileRecieverFlagCountryName"></field-text>
                <field-text col="6 12" name="mobileRecieverRef" {disabled}="disabled"
                            {visible}="visibles.mobileRecieverRef"></field-text>
                <field-text col="6 12" name="mobileRecieverRegistry" {disabled}="disabled"
                            {visible}="visibles.mobileRecieverRegistry"></field-text>
                <field-text name="mobileRecieverImo" {disabled}="disabled" {visible}="visibles.mobileRecieverImo"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-status {{#unless groups.dcp}}hidden{{/unless}}">
                <h2>{{msg 'fad'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.dcp}}hidden{{/unless}}">
                <field-text col="6 12" name="fadSchoolAssocCode" {disabled}="disabled"
                            {visible}="visibles.fadSchoolAssocCode" class="l30-f70"></field-text>
                <field-text col="6 12" name="fadBrand" {disabled}="disabled" {visible}="visibles.fadBrand" class="l30-f70"></field-text>
                <field-text col="6 12" name="fadBeaconId" {disabled}="disabled" {visible}="visibles.fadBeaconId" class="l30-f70"></field-text>
                <field-text col="6 12" name="fadBeaconType" {disabled}="disabled"
                            {visible}="visibles.fadBeaconType" class="l30-f70"></field-text>
                <field-text col="6 12" name="fadOperation" {disabled}="disabled" {visible}="visibles.fadOperation" class="l30-f70"></field-text>
                <field-text col="6 12" name="fadVisitType" {disabled}="disabled" {visible}="visibles.fadVisitType" class="l30-f70"></field-text>
            </div>

            <div class="header-group icon-left icon-m ui-icon-metoc {{#unless groups.meteoCondition}}hidden{{/unless}}">
                <h2>{{msg 'meteoCondition'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.meteoCondition}}hidden{{/unless}}">
                <field-text col="12 12" name="fadWaterTemperature" {format}="formats.fadWaterTemperature" {disabled}="disabled"
                            {visible}="visibles.fadWaterTemperature"></field-text>
                <field-text col="6 12" name="fadWindSpeed" {format}="formats.fadWindSpeed" {disabled}="disabled"
                            {visible}="visibles.fadWindSpeed"></field-text>
                <field-text col="6 12" name="fadWindDirection" {disabled}="disabled"
                            {visible}="visibles.fadWindDirection"></field-text>
                <field-text col="6 12" name="fadCurrentSpeed" {format}="formats.fadCurrentSpeed" {disabled}="disabled" {visible}="visibles.fadCurrentSpeed"></field-text>
                <field-text col="6 12" name="fadCurrentDirection" {disabled}="disabled" {visible}="visibles.fadCurrentDirection"></field-text>
            </div>

            <div class="inlineGroup {{#unless grids.gearsGrid}} hidden {{/unless}}">
                <div class="header-group icon-left icon-m ui-icon-fishHook">
                    <h2>{{msg 'gears'}}</h2>
                </div>
                <div id="{{id}}-gearsGrid"></div>
            </div>

            <div class="inlineGroup {{#unless grids.catchesGrid}} hidden {{/unless}}">
                <div class="header-group icon-left icon-m ui-icon-fish">
                    <h2>{{msg 'species'}}</h2>
                </div>
                <div id="{{id}}-catchesGrid"></div>
            </div>


            <div class="header-group icon-left icon-m ui-icon-info {{#unless groups.coe}}hidden{{/unless}}">
                <h2>{{msg 'field.kind.COE'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.coe}}hidden{{/unless}}">
                <field-text col="6 12" name="coe_startDate" class="icon-l icon-left ui-icon-calendar" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="coe_position" class="icon-l icon-left ui-icon-position" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="coe_zoneFullName" class="icon-l icon-left ui-icon-zones" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="coe_targetSpecies" label-key="field.targetSpecies" {disabled}="disabled" class="icon-l icon-left ui-icon-fish"></field-text>
            </div>
            <div class="inlineGroup {{#unless groups.coe}}hidden{{/unless}}">
                <div class="header-group icon-left icon-m ui-icon-fish">
                    <h2>{{msg 'species'}}</h2>
                </div>
                <div id="{{id}}-coe_catchesGrid"></div>
            </div>


            <div class="header-group icon-left icon-m ui-icon-info {{#unless groups.cox}}hidden{{/unless}}">
                <h2>{{msg 'field.kind.COX'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.cox}}hidden{{/unless}}">
                <field-text col="6 12" name="cox_startDate" class="icon-l icon-left ui-icon-calendar" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="cox_position" class="icon-l icon-left ui-icon-position" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="cox_zoneFullName" class="icon-l icon-left ui-icon-zones" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="cox_targetSpecies" label-key="field.targetSpecies" {disabled}="disabled" class="icon-l icon-left ui-icon-fish"></field-text>
            </div>
            <div class="inlineGroup {{#unless groups.cox}}hidden{{/unless}}">
                <div class="header-group icon-left icon-m ui-icon-fish">
                    <h2>{{msg 'species'}}</h2>
                </div>
                <div id="{{id}}-cox_catchesGrid"></div>
            </div>

            <div class="inlineGroup {{#unless grids.pfp_vesselGrid}} hidden {{/unless}}">
                <div class="header-group icon-left icon-m ui-icon-vessel">
                    <h2>{{msg 'field.kind.PFP'}}</h2>
                </div>
                <div id="{{id}}-pfp_vesselGrid"></div>
            </div>

            <div class="header-group icon-left icon-m ui-icon-info {{#unless groups.cvt}}hidden{{/unless}}">
                <h2>{{msg 'field.kind.CVT'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.cvt}}hidden{{/unless}}">
                <field-text col="6 12" name="cvt_name" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="cvt_iccat" label-key="field.iccat" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="cvt_imo" label-key="field.imoDonor" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="cvt_callSign" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="cvt_registry" label-key="field.irDonor" {disabled}="disabled"></field-text>
            </div>
            <div class="inlineGroup {{#unless groups.cvt}}hidden{{/unless}}">
                <div class="header-group icon-left icon-m ui-icon-fish">
                    <h2>{{msg 'species'}}</h2>
                </div>
                <div id="{{id}}-cvt_catchesGrid"></div>
            </div>

          {{#each dataModel.cvo_subItems}}
              <div class="header-group icon-left icon-m ui-icon-info {{#unless groups.cvo}}hidden{{/unless}}">
                  <h2>{{msg 'field.kind.CVO'}}</h2>
              </div>

              <div class="inlineGroup fields {{#unless groups.cvo}}hidden{{/unless}}">
                  <field-text col="6 12" name="{{@index}}_cvo_name" {disabled}="disabled"></field-text>
                  <field-text col="6 12" name="{{@index}}_cvo_iccat" label-key="field.iccat" {disabled}="disabled"></field-text>
                  <field-text col="6 12" name="{{@index}}_cvo_imo" label-key="field.imoDonor" {disabled}="disabled"></field-text>
                  <field-text col="6 12" name="{{@index}}_cvo_callSign" {disabled}="disabled"></field-text>
                  <field-text col="6 12" name="{{@index}}_cvo_registry" label-key="field.irDonor" {disabled}="disabled"></field-text>
              </div>

              <div class="inlineGroup {{#unless groups.cvo}}hidden{{/unless}}">
                  <div class="header-group icon-left icon-m ui-icon-fish">
                      <h2>{{msg 'species'}}</h2>
                  </div>
                  <div id="{{../id}}-{{@index}}_cvo_catchesGrid"></div>
              </div>
          {{/each}}

            <div class="header-group icon-left icon-m ui-icon-info {{#unless groups.jci}}hidden{{/unless}}">
                <h2>{{msg 'field.kind.JCI'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.jci}}hidden{{/unless}}">
                <field-text col="6 12" name="jci_time" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="jci_position" {disabled}="disabled" format="position"></field-text>
            </div>
            <div class="inlineGroup {{#unless groups.jci}}hidden{{/unless}}">
                <div class="header-group icon-left icon-m ui-icon-fish">
                    <h2>{{msg 'species'}}</h2>
                </div>
                <div id="{{id}}-jci_catchesGrid"></div>
            </div>

            <div class="header-group icon-left icon-m ui-icon-info {{#unless groups.bti}}hidden{{/unless}}">
                <h2>{{msg 'field.kind.BTI'}}</h2>
            </div>
            <div class="inlineGroup fields {{#unless groups.bti}}hidden{{/unless}}">
                <field-text col="6 12" name="bti_position" {disabled}="disabled" format="position"></field-text>
                <field-text col="6 12" name="bti_tugTowingVesselName" label-key="field.tugTowingVesselName"
                            {disabled}="disabled"></field-text>
                <field-text col="6 12" name="bti_tugTowingVesselIccat" label-key="field.tugTowingVesselIccat"
                            {disabled}="disabled"></field-text>
                <field-text col="6 12" name="bti_farmName" label-key="field.farmName" {disabled}="disabled"></field-text>
                <field-text col="6 12" name="bti_farmIccat" label-key="field.farmIccat" {disabled}="disabled"></field-text>
            </div>
        </field-item>

        <field-item item="dataModel" class="L-panel-content {{#unless fshlogOrigin}}hidden{{/unless}}">
            {{stacheAutoGenerated}}
        </field-item>

    </div>

</div>
