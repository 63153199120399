import {canStache} from "core-cmp/can";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import {PREF} from "core-cmp/Preferences";
import Mobile from "core-uda/model/mobile/Mobile";
import {MOBILE_CONST} from "core-uda/model/mobile/Mobile";
import {CUSTOMER_CONST} from "core-uda/model/customer/Customer";
import _ from "core-cmp/lodash";
import {RIGHTS} from "core-uda/Rights";
import {THM_PREF} from "thm/ThmPreferences";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import Toastr from "core-cmp/Toastr";
import {APP} from "core-uda/model/Resource";
import MobileInfoPanel from "thm/ui/common/mobile/MobileInfoPanel";
import BeaconLogPopup from "core-mobile/ui/beacon/BeaconLogPopup";
import BeaconLog from "core-uda/model/beacon/BeaconLog";
import $ from "thm/thm-jquery";
import MobileImage from "core-uda/model/mobile/MobileImage";
import MobileLifeEvent from "core-uda/model/mobileLifeEvent/MobileLifeEvent";
import InformationPopup from "core-cmp/msg/InformationPopup";
import MobileDuplicateSsasContactPopup from "thm/ui/common/mobile/MobileDuplicateSsasContactPopup";
import MobileThemisUpdateCustomersPopup from "thm/ui/common/mobile/MobileThemisUpdateCustomersPopup";
import MobileInfoPanelImageTplStache from "thm/ui/common/mobile/MobileInfoPanelImageTpl.stache";
import MobileThemisInfoPanelContactTplStache from "thm/ui/common/mobile/MobileThemisInfoPanelContactTpl.stache";
import MobileThemisInfoPanelSsasContactTplStache from "thm/ui/common/mobile/MobileThemisInfoPanelSsasContactTpl.stache";
import MobileThemisInfoPanelTplStache from "thm/ui/common/mobile/MobileThemisInfoPanelTpl.stache";
import Trajectory from "core-uda/model/position/Trajectory";
import SsasVessel from "core-uda/model/ssas/SsasVessel";
import SsasContact from "core-uda/model/ssas/SsasContact";
import SsasTestLog from "core-uda/model/ssas/SsasTestLog";
import BeaconTplStache from "thm/ui/common/mobile/BeaconTpl.stache";
import BeaconEditTplStache from "core-mobile/ui/beacon/BeaconTpl.stache";
import ShipownerTplStache from "thm/ui/common/mobile/ShipownerUITpl.stache";
import EditShipownerPartPopup from "core-mobile/ui/shipowner/EditShipownerPartPopup";
import FleetTplStache from "thm/ui/common/mobile/FleetUITpl.stache";
import Fleet from "core-uda/model/fleet/Fleet";
import FishingGearTplStache from "core-mobile/ui/fishingGear/FishingGearTpl.stache";
import EditFishingGearPopup from "core-mobile/ui/fishingGear/EditFishingGearPopup";
import EventsTabMobileTplStache from "thm/ui/common/mobile/EventsTabMobileTpl.stache";
import EventTplStache from "thm/ui/common/mobile/EventTpl.stache";
import CodeOptionsTpl from "thm/ui/common/mobile/CodeOptionsTpl.stache";
import EditEventPopup from "thm/ui/common/mobile/EditEventPopup";
import {canViewModel} from "core-cmp/can";
import "thm/ui/common/mobile/LicenceTplStyle.css";
import ExportTypePickerPopup from "thm/ui/common/popup/ExportTypePickerPopup";
import EditSsasTestLogPopup from "thm/ui/common/ssas/ssasTestLog/EditSsasTestLogPopup";
import ConfirmationPopup from "core-cmp/msg/ConfirmationPopup";

canStache.registerPartial("MobileInfoPanelImageTpl", MobileInfoPanelImageTplStache);
canStache.registerPartial("MobileThemisInfoPanelContactTpl", MobileThemisInfoPanelContactTplStache);
canStache.registerPartial("MobileThemisInfoPanelSsasContactTpl", MobileThemisInfoPanelSsasContactTplStache);

/**
 * @class thm.ui.common.mobile.MobileThemisInfoPanel
 * @parent thm.ui.themis.mobile
 * @constructor
 * Afficher les infos d'un mobile sous themis
 * @param {Object} options
 */
let MobileThemisInfoPanel = MobileInfoPanel.extend({
  template:        MobileThemisInfoPanelTplStache,
  i18nPrefix:      [
    'thm.ui.common.mobile.MobileInfoPanel.',
    'umv.ui.infopanel.MobileThemisInfoPanel.',
    'uda.mobile.',
    'uda.shipowner.',
    'uda.fishingGears.',
    'uda.vesselFishingGears.',
    'uda.beacons.',
    "uda.species.",
    "uda.licence.",
    "uda.fleet.field.type.",
    "uda.alert.field.kind.",
    "thm.ui.common.mobile.VesselTypeCode.",
    "thm.ui.common.mobile.SsasMobile."
  ],
  allowEdit:       true,
  allowClone:      true,
  disabledFields:  ['disabledFields'],
  typeCodeOptions: [
    {value: '', text: 'None', label: ''},
    {value: '01.1.0', text: 'PS (01.1.0)', label: 'ps'},
    {value: '01.1.1', text: 'PS1 (01.1.1)', label: 'ps1'},
    {value: '01.1.2', text: 'PS2 (01.1.2)', label: 'ps2'},
    {value: '01.2.0', text: 'LA (01.2.0)', label: 'la'},
    {value: '02.1.0', text: 'SB (02.1.0)', label: 'sb'},
    {value: '02.2.0', text: 'SV (02.2.0)', label: 'sv'},
    {value: '02.2.1', text: 'SDN (02.2.1)', label: 'sdn'},
    {value: '02.2.2', text: 'SSC (02.2.2)', label: 'ssc'},
    {value: '02.2.3', text: 'SPR (02.2.3)', label: 'spr'},
    {value: '02.9.0', text: 'SX (02.9.0)', label: 'sx'},
    {value: '03.1.1', text: 'TBB (03.1.1)', label: 'tbb'},
    {value: '03.1.2', text: 'OTB (03.1.2)', label: 'otb'},
    {value: '03.1.3', text: 'PTB (03.1.3)', label: 'ptb'},
    {value: '03.1.4', text: 'TBN (03.1.4)', label: 'tbn'},
    {value: '03.1.5', text: 'TBS (03.1.5)', label: 'tbs'},
    {value: '03.1.9', text: 'TB (03.1.9)', label: 'tb'},
    {value: '03.2.1', text: 'OTM (03.2.1)', label: 'otm'},
    {value: '03.2.2', text: 'PTM (03.2.2)', label: 'ptm'},
    {value: '03.2.3', text: 'TMS (03.2.3)', label: 'tms'},
    {value: '03.2.9', text: 'TM (03.2.9)', label: 'tm'},
    {value: '03.3.0', text: 'OTT (03.3.0)', label: 'ott'},
    {value: '03.4.9', text: 'OT (03.4.9)', label: 'ot'},
    {value: '03.5.9', text: 'PT (03.5.9)', label: 'pt'},
    {value: '03.9.0', text: 'TX (03.9.0)', label: 'tx'},
    {value: '04.1.0', text: 'DRB (04.1.0)', label: 'drb'},
    {value: '04.2.0', text: 'DRH (04.2.0)', label: 'drh'},
    {value: '05.1.0', text: 'LNP (05.1.0)', label: 'lnp'},
    {value: '05.2.0', text: 'LNB (05.2.0)', label: 'lnb'},
    {value: '05.3.0', text: 'LNS (05.3.0)', label: 'lns'},
    {value: '05.9.0', text: 'LN (05.9.0)', label: 'ln'},
    {value: '06.1.0', text: 'FCN (06.1.0)', label: 'fcn'},
    {value: '06.9.0', text: 'FG (06.9.0)', label: 'fg'},
    {value: '07.1.0', text: 'GNS (07.1.0)', label: 'gns'},
    {value: '07.2.0', text: 'GND (07.2.0)', label: 'gnd'},
    {value: '07.3.0', text: 'GNC (07.3.0)', label: 'gnc'},
    {value: '07.4.0', text: 'GNF (07.4.0)', label: 'gnf'},
    {value: '07.5.0', text: 'GTR (07.5.0)', label: 'gtr'},
    {value: '07.6.0', text: 'GTN (07.6.0)', label: 'gtn'},
    {value: '07.9.0', text: 'GEN (07.9.0)', label: 'gen'},
    {value: '07.9.1', text: 'GN (07.9.1)', label: 'gn'},
    {value: '08.1.0', text: 'FPN (08.1.0)', label: 'fpn'},
    {value: '08.2.0', text: 'FPO (08.2.0)', label: 'fpo'},
    {value: '08.3.0', text: 'FYK (08.3.0)', label: 'fyk'},
    {value: '08.4.0', text: 'FSN (08.4.0)', label: 'fsn'},
    {value: '08.5.0', text: 'FWR (08.5.0)', label: 'fwr'},
    {value: '08.6.0', text: 'FAR (08.6.0)', label: 'far'},
    {value: '08.9.0', text: 'FIX (08.9.0)', label: 'fix'},
    {value: '09.1.0', text: 'LHP (09.1.0)', label: 'lhp'},
    {value: '09.2.0', text: 'LHM (09.2.0)', label: 'lhm'},
    {value: '09.3.0', text: 'LLS (09.3.0)', label: 'lls'},
    {value: '09.4.0', text: 'LLD (09.4.0)', label: 'lld'},
    {value: '09.5.0', text: 'LL (09.5.0)', label: 'll'},
    {value: '09.6.0', text: 'LTL (09.6.0)', label: 'ltl'},
    {value: '09.9.0', text: 'LX (09.9.0)', label: 'lx'},
    {value: '10.1.0', text: 'HAR (10.1.0)', label: 'har'},
    {value: '11.1.0', text: 'HMP (11.1.0)', label: 'hmp'},
    {value: '11.2.0', text: 'HMD (11.2.0)', label: 'hmd'},
    {value: '11.9.0', text: 'HMX (11.9.0)', label: 'hmx'}
  ],
  codeOptions:     CodeOptionsTpl,

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    let me = this;

    dataModel.attr({
      imo:     "",
      mmsi:    "",
      name:    me.msg("Clone") + " - " + dataModel.attr("name"),
      beacons: ""
    });
  },

  /**
   * @override
   */
  bindEvents: function () {
    let me        = this,
        dataModel = me.viewModel.dataModel;

    me._super();
    APP().on("beaconStateChanged", function (ev, state, item) {
      // On supprime l'item de la liste
      if (state === "false") {
        APP('beacons', item.id).removeAttr('mobileId');
        if (dataModel.activeBeaconId === item.id) {
          APP('beacons', item.id).attr('active', false);
          dataModel.attr('activeBeaconId', undefined);
        }
      }
    });
  },

  /**
   * @override
   */
  bindViewModel: function () {
    let me = this;
    me._super();
    me.viewModel.attr('dataModel').on('flagId', function () {
      me.updateFlag();
    });

    me.viewModel.attr('dataModel').on('active', function (ev, newVal, oldVal) {
      me.viewModel.attr('activeChanged', false);
      if ((newVal != oldVal) && (newVal != undefined) && (oldVal != undefined)) {
        me.viewModel.attr('activeChanged', true);
      }

      me.viewModel.attr('activeChanged', false);
      if ((newVal !== oldVal) && (newVal !== undefined) && (oldVal !== undefined)) {
        me.viewModel.attr('activeChanged', true);
      }

      if (!me.viewModel.attr('showInactive')) {
        // newVal is undefined if form is rest
        if (!_.isNil(newVal) && oldVal) {
          InformationPopup.openPage({
            titleKey:    '_Caution',
            textKey:     'mobileActiveChanged',
            validateKey: '_Validate'
          });
        }
      }
    });

    me.viewModel.attr('dataModel').on('beaconReplacementRequired', function (ev, active) {
      if (active) {
        me.viewModel.attr("noReplacementRequired", false);
      } else {
        me.viewModel.attr("noReplacementRequired", true);
      }
    });

    me.viewModel.attr('dataModel').on('ssasTerminal', function () {
      me.viewModel.attr('showTerminalLevel', $.isTrue(me.viewModel.attr('dataModel.ssasTerminal')));
    });

    me.viewModel.on('dataModel.contact1Id', (ev, value, oldValue) => {
      if (!me.viewModel.attr('contact1') || me.viewModel.attr('contact1').id !== value) {
        if (!_.isNil(value) && value !== "") {
          me.viewModel.attr('contact1', APP('ssasContact').getById(value).attr());
        } else {
          me.viewModel.attr('contact1', null);
        }
        me.viewModel.attr('dataModel.contactChange', !me.viewModel.attr('dataModel.contactChange'));
      }

    });

    me.viewModel.on('dataModel.contact2Id', (ev, value) => {
      if (!me.viewModel.attr('contact2') || me.viewModel.attr('contact2').id !== value) {
        if (!_.isNil(value) && value !== "") {
          me.viewModel.attr('contact2', APP('ssasContact').getById(value).attr());
        } else {
          me.viewModel.attr('contact2', null);
        }
        me.viewModel.attr('dataModel.contactChange', !me.viewModel.attr('dataModel.contactChange'));
      }

    });

    me.viewModel.on('dataModel.contact3Id', (ev, value) => {
      if (!me.viewModel.attr('contact3') || me.viewModel.attr('contact3').id !== value) {
        if (!_.isNil(value) && value !== "") {
          me.viewModel.attr('contact3', APP('ssasContact').getById(value).attr());
        } else {
          me.viewModel.attr('contact3', null);
        }
        me.viewModel.attr('dataModel.contactChange', !me.viewModel.attr('dataModel.contactChange'));
      }

    });

    me.viewModel.attr('dataModel').on('apwCloseMonitoring', function (ev, value) {
      if (!me.viewModelUpdating && value && me.viewModel.attr("dataModel.currentStatusCodeId")) {
        let current = APP('statusCode', me.viewModel.attr("dataModel.currentStatusCodeId")),
            lowest  = current;
        APP('statusCode').each((i) => {
          lowest = lowest.period > i.period ? i : lowest;
        });
        if (lowest.id != current.id) {
          ConfirmationPopup.openSingleton({
            title:    me.msg('field.apwCloseMonitoring'),
            text:     me.msg('reduceReportingFrequency', lowest.period / 60),
            buttons:  [
              {action: "yes"},
              {action: "no"}
            ],
            onAction: function (action) {
              if (action === "yes") {
                me.viewModel.attr("dataModel.currentStatusCodeId", lowest.id)
              }
            }
          });
        }
      }
    });
  },

  /**
   * @override
   */
  initViewModel: function () {
    let me = this,
        maxLengthRegistryList;

    me._super();
    me.viewModel.attr("showInactive", false);
    me.viewModel.attr("showClient", RIGHTS("showClient"));

    // Traduction label
    me.typeCodeOptions.forEach((item) => {
      item.msg = me.msg(item.label);
    });
    me.viewModel.attr('typeCodeOptions', me.typeCodeOptions);
    me.viewModel.codeOptions = me.codeOptions;

    me.viewModel.attr('smsAuthorized', RIGHTS("sms"));
    me.viewModel.attr('beaconEditable', RIGHTS('mobile.allowBeaconLink'));
    me.viewModel.attr('normalReport', RIGHTS('mobile.normalReportingAllowed'));

    // THM-11782 - never shown sms except one case (see updateViewModel method)
    let contactBroadcastNoSms = CUSTOMER_CONST.contactBroadcast
    _.remove(contactBroadcastNoSms, mode => mode == 'sms');
    me.viewModel.attr("alertBroadcastModes", contactBroadcastNoSms);


    me.viewModel.attr("authorizedProcessOptions", MOBILE_CONST.authorizedProcessing);

    me.viewModel.attr("isApwAuthorized", RIGHTS("apw.authorized"));
    me.viewModel.attr('isApwStatusAutorized', RIGHTS('data.mobile.showAPWStatus'));
    me.viewModel.attr("apwDelays", RIGHTS("apw.delays").split(',').map((text) => {
      return {
        value: me.handleDelay(text),
        text:  text
      };
    }));
    me.viewModel.attr("lastApwStatusOptions", MOBILE_CONST.lastApwStatus);

    me.viewModel.attr("ownershipOptions", MOBILE_CONST.ownership);
    me.viewModel.attr("integrationStatusOptions", MOBILE_CONST.integrationStatus);

    me.viewModel.attr('monitors', MOBILE_CONST.monitorActivatedTarget);

    me.viewModel.attr("terminalLevelOptions", APP().getCapabilities('mobile', 'terminalLevels'));

    // Beacons
    me.viewModel.attr('beaconsTemplate', BeaconTplStache);
    me.viewModel.attr('beaconEditTemplate', BeaconEditTplStache);
    me.viewModel.attr('onChangeActiveBeacon', me.proxy(me.changeActiveBeacon));
    me.viewModel.attr('onDeleteBeacon', me.proxy(me.deleteBeacon));
    me.viewModel.attr('onBeaconUpdated', me.proxy(me.onBeaconUpdated));

    // Status
    me.viewModel.attr('hasZoneClassic', !RIGHTS('data.positions.displayStatisticZonesForPositions'));
    if (RIGHTS('data.positions.displayStatisticZonesForPositions')) {
      let json      = JSON.parse(RIGHTS("data.positions.zones")),
          zonesInfo = [];
      _.forEach(Object.keys(json), (label) => {
        if (json[label] !== "") {
          let parseName = label.charAt(0).toUpperCase() + label.slice(1);
          zonesInfo.push({name: "last" + parseName, label: json[label].label})
        }
      });
      me.viewModel.attr('zonesInfos', zonesInfo);
    }

    // Shipowners
    me.viewModel.attr('shipownerTemplate', ShipownerTplStache);
    me.viewModel.attr('shipownerEditPage', EditShipownerPartPopup.getId());

    // Fleets
    me.viewModel.attr('fleetLabel', me.msg('_Fleets'));
    me.viewModel.attr('fleetTemplate', FleetTplStache);
    me.viewModel.attr('fleetHandler', {
      getItemViewModel: me.proxy(me.getFleetItemViewModel)
    });
    me.viewModel.attr('onFleetRefresh', me.proxy(me.fleetRefresh));

    if (RIGHTS("user.domain") === "fishing") {
      // FishingGears
      me.viewModel.attr('fishingGearTemplate', FishingGearTplStache);
      me.viewModel.attr('fishingGearEditPage', EditFishingGearPopup.getId());
    }

    if (RIGHTS('data.registryName')) {
      maxLengthRegistryList = JSON.parse(RIGHTS('data.registryName'));
      me.viewModel.attr('maxLengthName', maxLengthRegistryList.mobileNameSize);
      me.viewModel.attr('maxLengthRegistry', maxLengthRegistryList.vesselRegistrySize);
    }

    me.viewModel.attr("beaconListToolbarActions", [{
      id:      "log",
      icon:    "grid",
      label:   me.msg("core-mobile.ui.beacon.BeaconLogPopup.title"),
      handler: me.onLog.bind(me)
    }]);

    me.viewModel.attr('styleOptions', APP("styles").filterByType("trajectory"));

    me.viewModel.attr('normalReportingAllowed', RIGHTS("mobile.normalReportingAllowed"));

    me.viewModel.attr('gfcmAllowed', RIGHTS("mobile.specificSectionAllowed")
      && RIGHTS("mobile.specificSectionCenter") == "GFCM");
    me.viewModel.attr('npfcAllowed', RIGHTS("mobile.specificSectionAllowed")
      && RIGHTS("mobile.specificSectionCenter") == "NPFC");

    me.viewModel.attr('vesselStatusOptions', MOBILE_CONST.vesselStatus.filter(status => status != 'deleted'));
    me.viewModel.attr('beamTypeOptions', MOBILE_CONST.beamTypes);
    me.viewModel.attr('depthTypeOptions', MOBILE_CONST.depthTypes);
    me.viewModel.attr('tonnageTypeOptions', MOBILE_CONST.tonnageTypes);
    me.viewModel.attr('lengthTypeOptions', MOBILE_CONST.lengthTypes);
    me.viewModel.attr('powerUnitOptions', MOBILE_CONST.powerUnits);
    me.viewModel.attr('freezerTypeOptions', MOBILE_CONST.freezerTypes);
    me.viewModel.attr('freezerCapacityUnitOptions', MOBILE_CONST.freezerCapacityUnits);
    me.viewModel.attr('npfcUnitOptions', MOBILE_CONST.npfcUnits);

    // SSAS tab
    me.viewModel.attr('ssasAllowed', RIGHTS("data.ssas")); // todo : penser à vérifier le customer (ssas) + la dispo des ressources
    me.viewModel.attr('isTrackingDomain', RIGHTS("user.domain") === "tracking");

    me.viewModel.attr('disableSsasTestDeclaration', false); // todo changer pour vérifier si le user est admin si admin false sinon true

    me.viewModel.attr('ssasVesselInfo', {});

    me.viewModel.attr('dataModel.contact1Id', "");
    me.viewModel.attr('contact1', "");
    me.viewModel.attr('dataModel.contact2Id', "");
    me.viewModel.attr('contact2', "");
    me.viewModel.attr('dataModel.contact3Id', "");
    me.viewModel.attr('contact3', "");
    me.viewModel.attr('dataModel.contactChange', false);

    // THM-11843 - NEMO
    me.viewModel.attr('showChargeLevel', false);

    me.viewModel.attr('showCloseMonitoring', RIGHTS("data.mobile.enableAPWCloseMonitoring"));
  },

  //Override
  updateViewModel: function () {
    let me    = this,
        isNew = _.isNil(me.data.id);

    me.viewModel.attr("showInactive", PREF('showInactiveMobile'));

    me.viewModel.attr("showInactive", PREF('showInactiveMobile'));

    me.viewModel.attr("isNew", isNew);

    if (!me.data.fishingGears) {
      me.data.attr('fishingGears', []);
    }

    if (!me.data.fleet) {
      me.data.attr('fleet', []);
    }

    if (!me.data.licences) {
      me.data.attr('licences', []);
    }

    if (!me.data.shipowners) {
      me.data.attr('shipowners', []);
    }

    me._super();

    // Prevent undefined value
    me.viewModel.attr("dataModel.isAisMobile", !!me.viewModel.attr("dataModel.isAisMobile"));

    me.viewModel.attr("image", "");
    me.viewModel.attr('rightAdditional', RIGHTS('mobile.additionalServices'));
    me.viewModel.attr('showTerminalLevel', $.isTrue(me.viewModel.attr('dataModel.ssasTerminal')));

    // If we set manually this value, the panel detect changes even if the user do nothing...
    // Manage this into server side if that necessary - THM-7945
    // if (me.viewModel.attr("dataModel.eedi") == undefined) {
    //   me.viewModel.attr("dataModel.eedi", 0)
    // }
    if (me.data.id && me.data.id.indexOf("MMSI-") === 0) {
      me.viewModel.attr("dataModel.isAisMobile", true);
      me.viewModel.attr("dataModel.mobileTypeName", me.newData.mobileTypeName);
    }
    // THM-11356 - show SMS only if the customer has the right
    let vesselCustomer = APP('customer', me.viewModel.attr('dataModel.customerId'));
    let allowSms = false;
    if(vesselCustomer?.parameters) {
      vesselCustomer.parameters.each(function (parameter) {
        if (parameter.name === "center.sms" && parameter.value === "true") {
          allowSms = true;
        }
      });
    }

    if (allowSms || RIGHTS("center.sms")) {
      me.viewModel.attr("alertBroadcastModes", ['never', 'sms', 'mail']);
    }else{
      me.viewModel.attr("alertBroadcastModes", ['never', 'mail']);
    }

    me.updateBeaconsViewModel();
    me.updateShipownersViewModel();
    me.updateFishingGearsViewModel();
    //me.updateLicencesViewModel();
    me.updateFleetsViewModel();

    if (!isNew && RIGHTS("data.ssas")) {
      me.loadSsasData(me.data.id).done((data) => {
        me.viewModel.attr('testLogs', data.data);
      });
    }

    // THM-7629 : Big data bug: manually get the lastPos for a mobile
    // TODO : à ameliorer. Cette "solution" au bug ajoute une requete, ce qui n'est pas idéal
    // la derniere position est d'ailleurs déjà récupéré par TrajectoryListCmp,
    // mais le loadForEdition du RightPanel ecrase ce resultat, on a donc besoin de re-faire la requete
    if (_.isNil(me.getDataModel().lastLoc)) {
      Trajectory.findByContextForLastPos(me.getDataModel().id).done(function (trajectory) {
        me.viewModel.dataModel.attr('lastLoc', trajectory ? trajectory.getLastPosition().loc : null);
        me.viewModel.dataModel.attr('lastSpeed', trajectory ? trajectory.getLastPosition().speed : null);
        me.viewModel.dataModel.attr('lastLocDate', trajectory ? (new Date(trajectory.getLastPosition().locDateMillis)).toString("yyyy-MM-dd_HH:mm:ss") : null);
        me.viewModel.dataModel.attr('lastHeading', trajectory ? trajectory.getLastPosition().heading : null);
        me.viewModel.attr('lastNature', trajectory ? trajectory.getLastPosition().nature : null);
        me.viewModel.attr('lastSource', trajectory ? trajectory.getLastPosition().source : null);
      });
    } else {
      me.viewModel.dataModel.attr('lastLoc', me.viewModel.dataModel.attr('lastLoc').attr());
    }

    if (isNew) {
      me.viewModel.dataModel.attr({
        lastAssistance:             false,
        lastAlimentation:           false,
        lastGpsConnected:           false,
        lastIntrusion:              false,
        customerId:                 APP().user.customerId,
        active:                     true,
        beaconReplacementRequired:  false
      })
    }

    // Check if beaconReplacementRequired is informed from UDA
    if (me.data.beaconReplacementRequired) {
      me.viewModel.attr("noReplacementRequired", false);
      me.viewModel.attr('dataModel.additionalInformation', me.data.additionalInformation);
      me.viewModel.dataModel.attr({
        beaconReplacementRequired: true
      });
    } else {
      me.viewModel.attr("noReplacementRequired", true);
      me.viewModel.dataModel.attr({
        beaconReplacementRequired: false
      });
    }

    if(RIGHTS('mobile.showChargeLevel')){
      me.viewModel.attr('showChargeLevel', true);
      if(me.viewModel.dataModel.attr('chargeLevel')){
        if(me.viewModel.dataModel.attr('chargeLevel') >= 30){
          // battery full green
          me.viewModel.attr('levelBattery', 'full');
          me.viewModel.attr('colorBattery', 'green');
        }else if(me.viewModel.dataModel.attr('chargeLevel') < 30 && me.viewModel.dataModel.attr('chargeLevel') >= 20){
          // battery low orange
          me.viewModel.attr('levelBattery', 'low');
          me.viewModel.attr('colorBattery', 'orange');
        }else if(me.viewModel.dataModel.attr('chargeLevel') < 20){
          // battery empty red
          me.viewModel.attr('levelBattery', 'warn');
          me.viewModel.attr('colorBattery', 'red');
        }
      } else {
        me.viewModel.attr('levelBattery', 'warn');
        me.viewModel.attr('colorBattery', 'gray');
      }
    }

    if (RIGHTS("data.ssas")) {
      // me.setSsasContact();
      me.setSsasFields();
      let hasArgosBeacon = !!_.find(me.viewModel.attr("dataModel.beacons"), (beaconId) => {
        let beacon = APP("beacons", beaconId);
        return beacon.satelliteProviderName === "CLS_ARGOS";
      });
      me.viewModel.attr('showSsasTestDeclaration', RIGHTS("data.ssasVessel.argosTestAuthorized") && hasArgosBeacon);
    }

    if(!me.viewModel.attr('eventsBeforeMobileSave')){
      me.viewModel.attr('eventsBeforeMobileSave', me.viewModel.attr('dataModel.events'));
    }

    const mobPositions = APP("positions").filter({mobileId: me.data.id});
    const lastPos = mobPositions[mobPositions.length - 1]; // seem to always be sorted by locDate
    me.viewModel.attr("lastPos", lastPos);
  },

  setSsasFields: function () {
    let me               = this,
        ssasVesselInfo   = me.data.ssasVessel,
        ssasVesselFields = _.cloneDeep(SsasVessel.boFields);

    if (_.isNil(ssasVesselInfo)) {
      return;
    }

    _.each(ssasVesselFields, (field) => {
      if (!_.isNil(ssasVesselInfo[field]) && field !== "versionNum") {
        me.viewModel.attr('dataModel').attr(field, ssasVesselInfo[field]);
      }
    });
  },

  setSsasContact: function () {
    let me             = this,
        ssasVesselInfo = me.data.ssasVessel;

    if (_.isNil(ssasVesselInfo)) {
      return;
    }

    if (!_.isNil(ssasVesselInfo.contact1Id)) {
      me.viewModel.attr('dataModel.contact1Id', ssasVesselInfo.contact1Id.toString())
    }
    if (!_.isNil(ssasVesselInfo.contact2Id)) {
      me.viewModel.attr('dataModel.contact2Id', ssasVesselInfo.contact2Id.toString())
    }
    if (!_.isNil(ssasVesselInfo.contact3Id)) {
      me.viewModel.attr('dataModel.contact3Id', ssasVesselInfo.contact3Id.toString())
    }


  },

  updateFlag: function () {
    let me     = this,
        flagId = me.viewModel.attr('dataModel.flagId'),
        flag   = APP("flags", flagId);

    me.viewModel.attr('dataModel.flagCountryName', flag ? flag.countryName : "-");
    me.viewModel.attr('dataModel.flagCountryCode', flag ? flag.countryCode : "-");
  },

  /**
   * @override
   */
  loadForEdition: function (data) {
    let deferred = new $.Deferred();

    Mobile.loadForEdition(data.id, data).done((mobile) => {
      let args = [];

      if (DATA_AUTHORIZED("licence")) {
        args.push(Licence.refreshAll());
      }
      if (DATA_AUTHORIZED("beacon")) {
        args.push(Beacon.refreshAll());
      }
      if (args.length === 0) {
        deferred.resolve(mobile);
      }

      $.when(...args).done(() => {
        deferred.resolve(mobile);
      })
    }).fail((error) => {
      deferred.reject(error);
    });
    return deferred;
  },

  getDataModelForVISSave: function () {
    let me               = this,
        dataModel        = me.getDataModel(),
        ssasVesselFields = _.cloneDeep(SsasVessel.boFields),
        modelRequest     = {id: me.data.ssasVessel.id};

    _.each(ssasVesselFields, function (field) {
      if (!_.isUndefined(dataModel[field])) {
        modelRequest[field] = dataModel[field];
      }
    });
    modelRequest.kind = 'ssasVessel';
    modelRequest.vesselId = dataModel.id;

    return modelRequest;
  },

  getDataModelVIS: function () {
    let me = this;

    if (!_.isEmpty(me.data.ssasVessel)) {
      return me.data.ssasVessel.attr();
    } else {
      return {};
    }

  },

  checkVISChanges: function (dataModelVIS, ssasVesselFields) {
    let me        = this,
        dataModel = me.getDataModel(),
        changes   = false;

    _.forEach(ssasVesselFields, (key) => {

      if (key !== "versionNum" && key !== "active") {
        if ((_.isNil(dataModelVIS[key]) && !_.isNil(dataModel[key]))
          || (!_.isNil(dataModelVIS[key]) && _.isNil(dataModel[key]))
          || (!_.isNil(dataModel[key]) && dataModel[key].toString() !== dataModelVIS[key].toString())) {
          changes = true;
        }
      }

    });

    return changes;


  },

  getDataModelForSave: function () {
    let me           = this,
        dataModel    = me.getDataModel(),
        modelRequest = {id: dataModel.id},
        mobileFields = APP().getCapabilities('mobile').fields;

    _.each(mobileFields, function (field, name) {
      if (!field.readOnly && dataModel[name] !== "undefined") {
        switch (name) {
          case "broadcastMode":
            modelRequest[name] = dataModel[name] || CUSTOMER_CONST.contactBroadcast[0];
            break;
          case "lengthOverall":
          case "lengthPp":
          case "draught":
          case "beam":
          case "maxSpeed":
          case "minSpeed":
          case "normalSpeed":
            modelRequest[name] = parseFloat(dataModel[name]);
            if (isNaN(modelRequest[name])) {
              delete modelRequest[name];
            }
            break;
          case "registrationPortId":
            if (!!dataModel[name] && typeof dataModel[name].slice == "function") {
              modelRequest[name] = dataModel[name].slice(4); // Remove PRT_ prefix
            } else {
              modelRequest[name] = "";
            }
            break;
          case "resumeDate":
            if (!_.isEmpty(dataModel[name])) {
              modelRequest[name] = dataModel[name];
            }
            break;
          case "lastMobDate":
            if (me.viewModel.attr('dataModel.' + name) == undefined) {
              modelRequest[name] = "";
            } else if (dataModel[name] != null && typeof dataModel[name] == "object") {
              modelRequest[name] = THM_PREF.toServerDate(dataModel[name]);
            } else {
              modelRequest[name] = dataModel[name];
            }
            break;
          default:
            modelRequest[name] = dataModel[name];
        }
      }
    });

    if (!RIGHTS('mobile.allowBeaconLink')) {
      delete modelRequest.beacons;
    }

    delete modelRequest.fishDeclarationMode;
    modelRequest.kind = 'vessel';
    return modelRequest;
  },

  handleDelay: function (apwDelay) {
    let unit  = apwDelay.replace(/[0-9]*/g, ''),
        value = Number(apwDelay.replace(/[dhms]/g, ''));

    // Transformer apwDelay en secondes
    switch (unit) {
      case 'd':
        value = value * 24 * 3600;
        break;
      case 'h':
        value = value * 3600;
        break;
      case 'm':
        value = value * 60;
        break;
      case 's':
      default:
        break;
    }

    return value;
  },

  modifyDiffBeforeCheck: function (diff) {
    let met                 = this,
        fromUndefinedFields = _.cloneDeep(SsasVessel.boFields);

    fromUndefinedFields.push('contactChange');

    _.each(diff.diff, (value, key) => {
      if (fromUndefinedFields.includes(key) || (value.from === 'undefined' && _.isNil(value.to))) {
        delete diff.diff[key];
        diff.count--;
      }
    });

    return diff;
  },

  /**
   * Function which allow to check if the mobileEvents have been updated or not
   * @param mobile
   * @returns {boolean}
   */
  checkMobileEventsAreUpdated: function(mobile) {
    let me = this,
        oldEvents,
        mobileEvents,
        diff;

    oldEvents = me.data.events ? me.data.events.attr() : [];
    mobileEvents = mobile.events ? mobile.events.attr() : [];

      diff = UTILS.diff(oldEvents, mobileEvents, {
        numberAsString: true,
        nilAsFalse:     true
      });

    return diff && diff.count > 0;
  },

  /**
   * @override
   */
  onSave: function (forceClose) {
    let me           = this,
        dataModel    = me.getDataModelForSave(),
        visDataModel = me.getDataModelVIS(),
        visChange    = me.checkVISChanges(visDataModel, _.cloneDeep(SsasVessel.boFields)),
        themisChange = me.checkChanges();

        if (!themisChange && !visChange) {
      Toastr.showToastr('info', 'noChanges');
      return;
    }

    // FIXME : Replace by !me.checkErrors and change checkErros methods body
    if (me.checkErrors(dataModel)) {
      return;
    }

    if (themisChange) {
      me.viewModel.attr('saveStatus', 0);
      me.save(forceClose);
    }

    if (RIGHTS('data.ssas') && visChange) {
      if (_.isUndefined(me.getDataModel().contact1Id)) {
        Toastr.showError(me.msg('contactUndefined'));
        return;
      } else {
        me.viewModel.attr('saveStatus', 0);
        me.save(forceClose, "ssasVessel");
      }
    }
  },

  /**
   * @override
   */
  createSaveItem: function (data, resourceId) {
    let me = this,
        id = _.isNil(resourceId) ? me.entityId : resourceId;

    return new RESOURCE_CLASS[id](data);
  },

  save: function (forceClose, resourceId) {
    let me   = this,
        data = _.isNil(resourceId) ? me.getDataModelForSave() : me.getDataModelForVISSave(),
        item = me.createSaveItem(data, resourceId);

    return me.listenStatus(me.saveItem(item, forceClose), "saveStatus").done(data => {
      me.onSaveSuccess(data, forceClose, resourceId);
    }).fail(function (errors) {
      me.onSaveError(errors);
    });
  },

  /**
   * @override
   * Save mobileimage then mobile
   */
  saveItem: function (mobile) {
    let me        = this,
        mobileImage,
        imageId   = me.viewModel.attr('dataModel.imageId'),
        imageSrc  = me.viewModel.attr('imgSrc'),
        imageData = {},
        imageDeferred,
        deferred;

    if (imageId) {
      imageData.id = imageId;
    }
    if (imageSrc !== '') {
      imageData.image = imageSrc;
    }

    mobileImage = new MobileImage(imageData);

    if (me.viewModel.attr('imgChanged')) {
      deferred = new $.Deferred();
      imageDeferred = (imageSrc === '' && !!imageId) ? mobileImage.destroy() : mobileImage.save();
      mobile.removeAttr('events');
      imageDeferred.done((image) => {
        me.saveMobile(mobile, image, imageSrc !== '').done((mobileData) => {
          me.saveEvents(mobile.events, mobile.id).done((data) => {
            deferred.resolve(mobileData);
          }).fail(() => {
            deferred.reject();
          });
        }).fail(() => {
          deferred.reject();
        })
      }).fail(() => {
        deferred.reject();
      });

      return deferred;
    }

    return me.saveMobile(mobile, mobileImage, imageSrc !== '');
  },

  saveEvents: function (events, mobileId) {
    let me       = this,
        deferred = new $.Deferred();

    const eventsToSend = new Array();

    if (events && events.length) {
      events.forEach(item => {
        item.attr("mobileId", mobileId);
        eventsToSend.push(item._data);
      });

      return MobileLifeEvent.updateList(eventsToSend, mobileId).done((data) => {
        deferred.resolve(data);
      });
    } else {
      if (me.data.events && me.data.events.length) { //cas où on n'a plus d'events mais on en avait avant
        return MobileLifeEvent.updateList(eventsToSend, mobileId).done((data) => {
          deferred.resolve(data);
        });
      }
      deferred.resolve(true);
    }

    return deferred;

  },

  saveMobile: function (mobile, image, hasImageSrc) {
    let me            = this,
        currentMobile = APP('mobiles', mobile.id),
        hasImage      = !!image && hasImageSrc,
        deferred      = new $.Deferred(),
        events        = [],
        eventsUpdated = me.checkMobileEventsAreUpdated(mobile);

    // Use Object.assign since we save mobile before events
    Object.assign(events, mobile.events);

    if (currentMobile) {
      currentMobile.image = hasImage ? image.image : null;
    }
    mobile.attr('imageId', hasImage ? image.id : '');
    me.viewModel.attr('imgChanged', false);

    mobile.removeAttr('events');

    mobile.attr('additionalInformation', me.viewModel.attr('dataModel.additionalInformation'));
    mobile.attr('beaconReplacementRequired', !me.viewModel.attr("noReplacementRequired"));

    mobile.save().done((mobileData) => {
      if(eventsUpdated){
        me.saveEvents(events, mobile.id).done((data) => {
          mobileData.attr("events", data);
          deferred.resolve(mobileData);
        }).fail(() => {
          deferred.reject();
        });
      }else{
        deferred.resolve(mobileData);
      }
    }).fail((msg) => {
      if (msg.exception === "INTERNAL_ERROR" && msg.key === "mobileCreateError" && msg.args[0]) {
        Toastr.showError(msg.args[0], 5000);
      }
      deferred.reject();
    });

    return deferred;
  },

  /**
   * @override
   */
  onSaveSuccess: function (data, forceClose, resourceId) {
    let me = this;

    if (resourceId) {
      me.data.ssasVessel = data;
    }

    if (!me.viewModel.attr('showInactive')) {
      if (data.active) {
        me._super(data, forceClose);
        me.updateBeaconModel(data.id, data.beacons);
      } else {
        Toastr.showToastr('success', "mobileDisactivated");
        APP().setById(me.entityId, data.id, null);
        if (me.managementMode) {
          setTimeout(function () {
            me.close();
          }, 500);
        }
      }
    }

    if (!RIGHTS('center.duplicationBlocking') && data.warnMessage) {
      Toastr.showToastr('warning', "mobileDuplicatedID", data.warnMessage);
    }

    me._super(data, forceClose);
    me.updateBeaconModel(data.id, data.beacons);

  },

  /****************** BEACONS ******************/

  updateBeaconModel: function (id, beacons) {
    let me = this,
        beacon;

    if (!beacons || beacons.length === 0 || APP().getModelSize('beacons') === 0) {
      return;
    }

    _.each(beacons, function (beaconId) {
      beacon = APP('beacons', beaconId);
      if (beacon) {
        beacon.attr('mobileId', id);
      }
    });
  },

  updateBeaconsViewModel: function () {
    let me     = this,
        beacon = APP('beacons', me.viewModel.dataModel.activeBeaconId);

    if (beacon) {
      beacon.attr("active", true);
    }
  },

  deleteBeacon: function (wc, dom, wcEvent, wcList, item) {
    let me = this,
        beacon,
        beacons;

    if (!item) {
      return;
    }

    beacon = APP("beacons", item.id);
    beacons = me.viewModel.attr("dataModel.beacons") || [];

    beacon.removeAttr("mobileId");

    if (beacon.active) {
      beacon.attr("active", false);
      if (beacons.length > 0) {
        let beaconTmp = _.find(wcList.itemOptions, {id: beacons[0]});
        me.viewModel.attr("dataModel.activeBeaconId", beaconTmp.id);
        beaconTmp.attr("active", true);
      } else {
        me.viewModel.attr("dataModel.activeBeaconId", "");
      }
    }
  },

  changeActiveBeacon: function (wc, dom, wcEvent, wcList, item) {
    let me = this,
        activeBeaconId,
        beacons;

    if (!item) {
      return;
    }

    activeBeaconId = item.id;
    beacons = me.viewModel.attr("dataModel.beacons") || [];

    _.each(beacons, (beacon) => {
      let beaconTmp = _.find(wcList.itemOptions, {id: beacon});
      beaconTmp.attr('active', activeBeaconId === beaconTmp.id);
    });

    // Request model
    me.viewModel.attr('dataModel.activeBeaconId', activeBeaconId);

  },

  onBeaconUpdated: function (wc, dom, wcEvent, wcList, item) {
    let me = this,
        state,
        beacons;

    if (!item) {
      return;
    }

    beacons = me.viewModel.attr("dataModel.beacons") || [];
    state = !!_.find(beacons, (beaconId) => beaconId === item.id);

    // if state is false, we deleted all items list
    if (state === true) {
      me.deleteBeacon(wc, dom, wcEvent, wcList, item);
    } else if (state === false) {
      me.viewModel.attr("dataModel.activeBeaconId", "");
    } else if (!beacons.length) {
      item.attr('active', true);
      me.viewModel.dataModel.attr('activeBeaconId', item.id);
    }
  },

  /****************** SHIPOWNERS ******************/

  updateShipownersViewModel: function () {
    let me = this;

    me.viewModel.attr('shipownerEditParams', {
      customerId: me.data.customerId
    });
  },

  /****************** FLEETS ******************/

  getFleets: function () {
    var me    = this,
        array = [];

    _.each(me.data.fleets, function (v) {
      var fleet = APP().getById('fleets', v);
      if (!!fleet) {
        array.push(fleet);
      }
    });

    me.viewModel.attr('fleets', array);
  },

  updateFleetsViewModel: function () {
    let me = this;
    me.getFleets();

    let fleets    = me.viewModel.attr('fleets'),
        fleetList = [];
    _.each(fleets, function (fleet) {
      fleetList.push(fleet.id);
    });
    me.viewModel.dataModel.attr('fleet', fleetList);
  },

  fleetRefresh: function (wc, dom, wcEvent, wcList) {
    let me = this;
    if (wcList) {
      let mobileId  = me.getDataModel().id,
          fleetList = [];
      Fleet.findBy({selectBy: "mobile:" + mobileId}).done((data) => {
        me.viewModel.attr('fleets', data);
        _.each(data, function (fleet) {
          fleetList.push(fleet.id);
        });
        me.viewModel.dataModel.attr('fleet', fleetList);
      });
    }
  },

  getFleetItemViewModel: function (item, index) {
    let me = this;
    return {
      id:        item.id,
      item:      item,
      typeClass: "ui-icon-" + (item.kind === "mobileList" ? "mobile" : "search"),
      typeLabel: Fleet.getKindLabel(item.kind)
    };
  },

  /**
   * Set customerId params for FishingGearEditPage
   */
  updateFishingGearsViewModel: function () {
    let me = this;

    me.viewModel.attr('fishingGearEditParams', {
      customerId: me.data.customerId
    });
  },

  onLog: function (ev) {
    let me = this;

    // Do not abort BeaconLog openning for mobile without beacons. It may havec had beacons.
    BeaconLog.findBy({selectBy: "mobile:" + me.data.id}).done((data) => {
      BeaconLogPopup.openPage({
        data: data
      });
    });
  },

  onExportSsasContact: function (ev) {
    let me = this;

    ExportTypePickerPopup.openPage({
      list:     ['pdf', 'xlsx'],
      onExport: me.proxy(me.downloadVISReport)
    });
  },

  onEditSsasTestLogComment: function (ev) {
    let me      = this,
        id      = $(ev.currentTarget).attr('data-id'),
        testLog = _.find(me.viewModel.attr('testLogs'), (t) => t.id === id);

    EditSsasTestLogPopup.openPage({
      save:    me.proxy(me.saveSsasTestLog),
      testLog: testLog
    });
  },

  onDuplicateSsasContact: function (ev) {
    let me = this;

    MobileDuplicateSsasContactPopup.openPage({
      vessel: me.data
    });

  },

  saveSsasTestLog: function (testLog) {
    let me = this;

    SsasTestLog.updateComment(testLog.id, me.data.id, testLog.comment).done((log) => {
      let testLog = _.find(me.viewModel.attr('testLogs'), (t) => t.id === log.id);
      testLog.attr('comment', log.comment);
      Toastr.showSuccess(me.msg("ssasCommentSuccess"), 3000);
    }).fail((err) => {
      console.error("ERROR while editing ssas test log comment : " + err);
      Toastr.showError(me.msg("ssasCommentError"), 3000);
    });
  },

  loadSsasData: function (id) {
    let me       = this,
        deferred = new $.Deferred();

    SsasTestLog.findOne(id).done((data) => {
      deferred.resolve(data);
    });

    return deferred;
  },

  downloadVISReport: function (type) {
    let me         = this,
        vesselId   = me.viewModel.dataModel.id,
        customerId = APP().user.customerId,
        request    = new XMLHttpRequest(),
        format     = type === "pdf" ? "application/pdf" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        params     = 'application=umv' + '&format=' + format + '&vesselId=' + vesselId + '&customerId=' + customerId;

    request.open("GET", UDA_URL + "ssasVessel/export?" + params, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.setRequestHeader(APP().useJwtToken ? 'jwt' : 'token', APP().user.token);
    request.responseType = 'blob';

    request.onload = function (e) {
      if (this.status === 200) {
        let blob     = this.response,
            fileName = "VIS Export - " + me.viewModel.dataModel.name + " - " + PREF().format("date", new Date()).replace(/\//g, "-") + "." + type;
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          let downloadLink      = window.document.createElement('a'),
              contentTypeHeader = request.getResponseHeader("Content-Type");

          downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: contentTypeHeader}));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    };
    request.send();
  },

  /****************** POPUP CUSTOMER ******************/

  onEditionCustomer: function () {
    let me = this;

    MobileThemisUpdateCustomersPopup.openPage({
      customerId: me.data.customerId,
      customer: me.data.customerCompany,
      mobileName: me.data.name,
      mobileId: me.data.id
    });
  },


  /**
   * @override
   */
  loadData: function (...args) {
    const me = this;
    const [data] = args;

    // sort events by eventDate
    const events = data.attr('events');
    if(events){
      const sortedEvents = [...events].sort(({eventDate:d1},{eventDate:d2}) => d1.getTime() < d2.getTime() ? -1 : 1);
      data.attr('events', sortedEvents);
    }

    me._super(...args);
  },



  /****************** LICENCES ******************/

  /*updateLicencesViewModel: function () {
   let me = this;

   me.viewModel.attr('licenceItems', APP("licences").findAvailableForMobile(me.data));
   }*/

});
export default MobileThemisInfoPanel;
