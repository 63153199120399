import Page from "core-cmp/Page";
import {PREF} from "core-cmp/Preferences";
import Toastr from "core-cmp/Toastr";
import {UDA_URL} from "core-uda/model/ResourceDAO";
import _ from "core-cmp/lodash";
import $ from "thm/thm-jquery";
import {APP} from "core-uda/model/Resource";
import {RIGHTS} from "core-uda/Rights";
import ErsActivity from "core-uda/model/ersActivity/ErsActivity";
import ErsActivityEditPageTplStache from "thm/ui/common/ersActivity/ErsActivityEditPageTpl.stache";
import ErsActivitySpeciesTpl from "thm/ui/common/ersActivity/ErsActivitySpeciesTpl.stache";
import EditActivitySpeciesPopup from "thm/ui/common/ersActivity/EditActivitySpeciesPopup";
import ConfirmationPopup from "core-cmp/msg/ConfirmationPopup";

let ErsActivityEditPage = Page.extend({
  id:              "ersActivityEditPage",
  template:        ErsActivityEditPageTplStache,
  routerType:      'popup',
  modal:           true,
  i18nPrefix:      ['thm.ui.common.ersActivity.ErsActivityEditPage.', 'uda.ersActivity.'],
  autoBindActions: true,
  popupWidth:      500,

  /**
   * @override
   */
  initViewModel: function () {
    let me = this;

    me._super();

    me.viewModel.attr({
      activity: {
        startDate: new Date(),
        endDate:   new Date(),
        reason:    '',
        species:   []
      }
    });
    me.viewModel.speciesTemplate = ErsActivitySpeciesTpl;
    me.viewModel.ersActivitySpeciesEditParams = {};
    me.viewModel.attr('title', me.msg('title'));
    me.viewModel.attr('ersActivitySpeciesEditPage', EditActivitySpeciesPopup.getId());
  },

  /**
   * @override
   */
  doBeforeOpen: function () {
    let me               = this,
        item             = me.context.activity,
        authorizedFields = me.context.authorizedFields,
        species;

    me.viewModel.attr('groups', me.context.groups);
    me.viewModel.attr('datesChanged', false);

    me.endDateField = _.find(authorizedFields[item.kind].fields, function (f) {
      return f.field === 'endDate';
    });
    me.speciesGridField = _.find(authorizedFields[item.kind].grids, function (f) {
      // modif for https://gitlab.cls.fr/mobility/app-thm/commit/c8f0f14a9ad6e28f8785bcf447524f6b53773926
      return f.gridName === 'catchesGrid';
    });
    me.dealershipSignatureDateField = _.find(authorizedFields[item.kind].fields, function (f) {
      return f.field === 'dealershipSignatureDate';
    });
    me.dealershipExpirationDateField = _.find(authorizedFields[item.kind].fields, function (f) {
      return f.field === 'dealershipExpirationDate';
    });

    me.viewModel.attr('activity.startDate', new Date(item.timeMillis));
    me.viewModel.attr("hasEndDate", !!me.endDateField);
    me.viewModel.attr('activity.endDate', !!me.endDateField && !!_.get(item, me.endDateField.place) ? new Date(_.get(item, me.endDateField.place)) : undefined);
    me.viewModel.attr('activity.dealershipSignatureDate', !!me.dealershipSignatureDateField && !!_.get(item, me.dealershipSignatureDateField.place) ? new Date(_.get(item, me.dealershipSignatureDateField.place)) : undefined);
    me.viewModel.attr('activity.dealershipExpirationDate', !!me.dealershipExpirationDateField && !!_.get(item, me.dealershipExpirationDateField.place) ? new Date(_.get(item, me.dealershipExpirationDateField.place)) : undefined);

    me.viewModel.attr("hasSpecies", !!me.speciesGridField);
    species = !!me.speciesGridField ? _.get(item, me.speciesGridField.place) : [];
    me.viewModel.attr("activity.species", !!species ? species : []); // _.get(item, me.speciesGridField.place) can return undefined

    me.viewModel.attr('activity.reason', item.reason);
  },

  checkDatesLimitAuthorized: function () {
    let me     = this,
        doSave = true;

    // Check if start date < end date
    if (!!me.endDateField) {
      if (!!me.viewModel.attr('activity.endDate') && me.viewModel.attr('activity.startDate').getTime() > me.viewModel.attr('activity.endDate').getTime()) {
        Toastr.showError(me.msg('startDateMustBeOlderThanEndDate'), 10000);
        return false;
      }
    }

    if (!!me.context.activity.tripId && (me.isStartDateChanged() || me.endDateField && me.isEndDateChanged())) {
      $.ajax({
        url:         UDA_URL + "ersActivity/infosToAuthUpdate",
        data:        {tripId: me.context.activity.tripId},
        type:        'POST',
        contentType: 'application/x-www-form-urlencoded'
      }).done(function (datas) {
        let preLimitDate            = new Date(datas.preLimit),
            postLimitDate           = !!datas.postLimit ? new Date(datas.postLimit) : null,
            tripOpenDate            = new Date(datas.tripOpenDate),
            tripCloseDate           = !!datas.tripCloseDate ? new Date(datas.tripCloseDate) : null,

            // Check if the activity dates exceed the previous trip close date
            // There can't be activities on other trip timeline
            preLimitDateExceeded    = preLimitDate.getTime() >= me.viewModel.attr('activity.startDate').getTime() ||
              !!me.viewModel.attr('activity.endDate') && preLimitDate.getTime() >= me.viewModel.attr('activity.endDate').getTime(),

            // Check if the activity dates exceed the next trip open date
            // There can't be activities on other trip timeline
            postLimitDateExceeded   = !!postLimitDate &&
              (postLimitDate.getTime() <= me.viewModel.attr('activity.startDate').getTime() ||
                !!me.viewModel.attr('activity.endDate') && postLimitDate.getTime() <= me.viewModel.attr('activity.endDate').getTime()),

            // Check if the activity start date exceed the current trip open date and close date
            // If the activity is the open activity of the trip, the start and end date can change. New start date will replace the current open trip date
            tripOpenDateExceed      = me.context.activity.id !== datas.tripOpenActivityId &&
              me.viewModel.attr('activity.startDate').getTime() < tripOpenDate.getTime() ||
              !!me.viewModel.attr('activity.endDate') && me.viewModel.attr('activity.endDate').getTime() < tripOpenDate.getTime(),

            // Check if the activity end date exceed the current trip open date and close date
            // Same as the tripOpenDateExceed, if the activity is the close activity of the trip, that will replace the close activity of the trip
            tripCloseDateExceed     = !!tripCloseDate &&
              (me.context.activity.id !== datas.tripCloseActivityId &&
                me.viewModel.attr('activity.startDate').getTime() > tripCloseDate.getTime() ||
                !!me.viewModel.attr('activity.endDate') && me.viewModel.attr('activity.endDate').getTime() > tripCloseDate.getTime()),

            // Open trip date cannot be after the close trip date
            openDateAfterCloseDate  = !!tripCloseDate &&
              (me.context.activity.id === datas.tripOpenActivityId &&
                me.viewModel.attr('activity.startDate').getTime() >= tripCloseDate.getTime()),

            // Close trip date cannot be before the open trip date
            closeDateBeforeOpenDate = me.context.activity.id === datas.tripCloseActivityId &&
              me.viewModel.attr('activity.startDate').getTime() <= tripOpenDate.getTime(),

            // TRA or LAN activity can be after the close trip date
            activityTraOrLan        = (me.context.activity.kind === 'TRA' && !!me.context.activity.portId) || me.context.activity.kind === 'LAN',

            toastrMsg,
            tripDateExceededMsg;

        if (preLimitDateExceeded || postLimitDateExceeded) {
          toastrMsg = preLimitDateExceeded ? me.msg('textPreDateExceed', PREF().format('dateTime', preLimitDate)) :
            me.msg('textPostDateExceed', PREF().format('dateTime', postLimitDate));
          Toastr.showError(toastrMsg, 10000);

        } else if (openDateAfterCloseDate || closeDateBeforeOpenDate) {
          toastrMsg = openDateAfterCloseDate ? me.msg('textOpenDateAfterCloseDate', PREF().format('dateTime', tripCloseDate)) :
            me.msg('textCloseDateBeforeOpenDate', PREF().format('dateTime', tripOpenDate));

          Toastr.showError(toastrMsg, 10000);

        } else if (tripOpenDateExceed || (tripCloseDateExceed && !activityTraOrLan)) {
          tripDateExceededMsg = tripOpenDateExceed ? me.msg('textNewDateExceedTripOpenDateConfirmation', PREF().format('dateTime', tripOpenDate)) :
            me.msg('textNewDateExceedTripCloseDateConfirmation', PREF().format('dateTime', tripCloseDate));

          ConfirmationPopup.openSingleton({
            title:    me.msg('titleModificationDateConfirmation'),
            text:     tripDateExceededMsg,
            buttons:  [
              {action: "yes"},
              {action: "no"}
            ],
            onAction: function (action) {
              if (action === 'yes') {
                me.doSave();
              }
            }
          });
        } else {
          me.doSave();
        }
      });
      doSave = false;
    }

    return doSave;
  },

  isStartDateChanged: function () {
    let me = this;

    return !!me.viewModel.attr('activity.startDate') && me.viewModel.attr('activity.startDate').getTime() !== me.context.activity.timeMillis;
  },

  isEndDateChanged: function () {
    let me = this;

    return !!me.viewModel.attr('activity.endDate') && me.viewModel.attr('activity.endDate').getTime() !== _.get(me.context.activity, me.endDateField.place);
  },

  doSave: function () {
    let me   = this,
        item,
        data = me.context.activity.attr();

    data.time = me.viewModel.attr('activity.startDate');
    data.reason = me.viewModel.attr('activity.reason');

    if (me.endDateField && !!me.viewModel.attr('activity.endDate')) {
      _.set(data, me.endDateField.place, me.viewModel.attr('activity.endDate').getTime());
    }
    if (me.speciesGridField && !_.isEmpty(me.viewModel.attr("activity.species"))) {
      _.set(data, me.speciesGridField.place, me.viewModel.attr("activity.species").attr());
    }
    if (me.dealershipSignatureDateField && !!me.viewModel.attr('activity.dealershipSignatureDate')) {
      _.set(data, me.dealershipSignatureDateField.place, me.viewModel.attr("activity.dealershipSignatureDate").getTime());
    }
    if (me.dealershipExpirationDateField && !!me.viewModel.attr('activity.dealershipExpirationDate')) {
      _.set(data, me.dealershipExpirationDateField.place, me.viewModel.attr("activity.dealershipExpirationDate").getTime());
    }

    // Can't same Activity if position THM-9179
    delete data.position;
    item = new ErsActivity(data);

    me.listenStatus(item.save(), "saveStatus").done((datas) => {
      let gridUreMain = Page.get('thm-ureMain'),
          gridUmeMain = Page.get('thm-umeMain');

      if (APP().gridMode === 'UME') {
        gridUmeMain.onRefresh();
      } else {
        gridUreMain.gridElement.refreshRowData(datas.tripId);
        let refreshMDDeferred = gridUreMain.gridElement.refreshMasterDetailGrid(datas.tripId, "ersActivity#tripId");
        if (!_.isUndefined(refreshMDDeferred)) {
          gridUreMain.gridElement.refreshMasterDetailGrid(datas.tripId, "ersActivity#tripId").done(() => {
            gridUreMain.openEditPageFor({type: 'ersActivity', data: datas});

            // Waiting for grid redraw
            _.delay(() => {
              gridUreMain.gridElement.setSelectedMasterDetailRow(datas.tripId, "ersActivity#tripId", datas.id);
            }, 500);
          });
        } else {
          gridUreMain.data.push(datas);
          gridUreMain.setGridData(gridUreMain.data);
          gridUreMain.gridElement.setSelectRowById(datas.id);
          gridUreMain.openEditPageFor({type: 'ersActivity', data: datas});
        }
      }

      _.delay(me.close.bind(me), 1000);
    });
  },

  onSubmit: function (event) {
    let me = this;

    if (me.checkDatesLimitAuthorized()) {
      me.doSave();
    }
  },

  onCancel: function (event) {
    let me = this;

    me.close();
  }
});

export default ErsActivityEditPage;
