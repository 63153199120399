import {COLOR_UTIL} from "core-cmp/util/ColorUtil";
import {PREF} from "core-cmp/Preferences";
import {TRAJECTORY_STYLE_HELPER} from "core-mobile/service/style/TrajectoryStyleHelper";
import TrajectoryStyleEngine from "core-mobile/service/style/TrajectoryStyleEngine";
import _ from "core-cmp/lodash";
import {THM_TRAJECTORY_STYLE_HELPER} from "thm/service/style/ThmTrajectoryStyleHelper";

/**
 * Modèle de style "Fishing"
 */
let ThmBourbonStyleEngine = TrajectoryStyleEngine.extend({
  modelName:     "Bourbon",
  version:       2,
  specificRules: null,

  /**
   * Constructeur.
   */
  init: function () {
    let me = this;

    me.specificRules = {
      "base":                {
        strokeColor: "#F64747"
      },
      "bourbon":             {
        strokeColor: "#1F3A93"
      },
      "lastPoint#noHeading": {
        extend: "point#noHeading"
      },
      "lineDefault":         {
        strokeDashstyle: "solid",
        strokeColor:     "#e77801",
        strokeWidth:     2,
        strokeOpacity:   1
      },
      "lineLevel1":          {
        speed:       1,
        strokeColor: "#FABC02"
      },
      "lineLevel2":          {
        speed:       3,
        strokeColor: "#66B032"
      },
      "line":                function (feature, attr) {
        let trajectory       = attr.trajectory,
            internalRef      = trajectory.mobile ? trajectory.mobile.internalRef : "",
            isBourbon        = internalRef ? internalRef.indexOf("MS") == 0 : false,
            speed            = trajectory.speedFor(attr.start),
            speedLevel1      = this.definitions["lineLevel1"].speed,
            speedLevel2      = this.definitions["lineLevel2"].speed,
            duration         = trajectory.durationFor(attr.start),
            breakRouteValue  = PREF().breakRouteValue,
            useBreakRoute    = PREF().breakRoute,
            lineClazz        = "lineDefault",
            strokeWidth      = 1,
            customAttributes = me.getCustomAttribut(attr, "line"),
            position         = attr.bo ? attr.bo.positions[attr.end] : undefined;

        if (!isNaN(breakRouteValue)) {
          /** On convertit en secondes */
          breakRouteValue = breakRouteValue * 60;
          if (duration > breakRouteValue && useBreakRoute) {
            if (this.definitions.lineBreakroute.strokeDashstyle == "hidden") {
              return "hidden";
            }
            lineClazz = "lineBreakroute";
          }
        }

        // THM-10857 ::: When we have selected points we higlight the line
        if (position && position.multiSelect && !position.multiSelectExclusion) {
          return _.assign({
            "extend": "backLine"
          }, customAttributes);
        }

        if (isBourbon) {
          strokeWidth = 3;
        }

        /** on regarde quelle valeur est la plus grande */
        let minValue, maxValue;
        if (speedLevel1 > speedLevel2) {
          minValue = [speedLevel2, "lineLevel2"];
          maxValue = [speedLevel1, "lineLevel1"];
        } else {
          minValue = [speedLevel1, "lineLevel1"];
          maxValue = [speedLevel2, "lineLevel2"];
        }

        if (speed > maxValue[0]) {
          return {
            extend:      lineClazz + " " + maxValue[1],
            strokeWidth: strokeWidth
          };
        }
        if (speed > minValue[0]) {
          return {
            extend:      lineClazz + " " + minValue[1],
            strokeWidth: strokeWidth
          };
        }

        return {
          extend:      lineClazz,
          strokeWidth: strokeWidth
        };
      },
      "cluster":             function (feature, attributes) {
        if (attributes.hasOwnProperty("count")) {
          var count = attributes.count || 1;
          if (count >= 10000) {
            return "cluster#>=10000";
          }
          if (count >= 1000) {
            return "cluster#>=1000";
          }
          if (count >= 500) {
            return "cluster#>=500";
          }
          if (count >= 100) {
            return "cluster#>=200";
          }
          return "cluster#>1";
        }
        return "cluster#>1";
      },
      "clusterPoint":        {
        symbolType:    "circle"
        , strokeColor: "#000000"
        , strokeWidth: 3
      },
      "cluster#>1":          {
        extend:          "clusterPoint clusterLabel"
        , fillColor:     "rgba(1, 224, 0, 1)"
        , strokeOpacity: .7
        , symbolRadius:  14
      },
      "cluster#>=200":       {
        extend:          "clusterPoint clusterLabel"
        , fillColor:     "rgba(238, 239, 9, 1)"
        , strokeOpacity: .7
        , symbolRadius:  17
      },
      "cluster#>=500":       {
        extend:          "clusterPoint clusterLabel"
        , fillColor:     "rgba(244, 166, 0, 1)"
        , strokeOpacity: .7
        , symbolRadius:  19
      },
      "cluster#>=1000":      {
        extend:          "clusterPoint clusterLabel"
        , fillColor:     "rgba(208, 0, 4, 1)"
        , strokeOpacity: .7
        , symbolRadius:  21
      },
      "cluster#>=10000":     {
        extend:         "clusterPoint clusterLabel"
        , fillColor:    "rgba(32, 32, 32, 1)"
        , symbolRadius: 24
      },
      "getColor":            function (properties, useSVG) {
        let definition  = TRAJECTORY_STYLE_HELPER.getValue({
              type:   "mobileDynamicColors",
              model:  properties.instance.definitions,
              select: properties.dynamicColorType,
              id:     properties[properties.dynamicColorType]
            }),
            strokeValue = "",
            strokeColor, color;

        if (properties.isEstimated) {
          strokeValue = "estimationPoint";
        }

        if (properties.isSelected || properties.isMultiSelected) {
          strokeValue = "selected";
        }

        if (strokeValue == "") {
          if (properties.isBourbon) {
            strokeValue = "bourbon";
          } else {
            strokeValue = "base";
          }
        }

        if (strokeValue == "") {
          strokeValue = "defaultPoint";
        }
        strokeColor = properties.instance.definitions[strokeValue].strokeColor;
        color = definition;

        if (!useSVG) {
          return {
            fillColor:   color.fillColor,
            strokeColor: strokeColor
          };
        }
        return {
          fillColor:   COLOR_UTIL.hexToId(color.fillColor),
          strokeColor: COLOR_UTIL.hexToId(strokeColor)
        };
      },
      "pointLabel":             {
        label:           function (feature, attributes) {
          return THM_TRAJECTORY_STYLE_HELPER.getLabel({
            model:    this.definitions,
            isLast:   false,
            position: attributes.estimatedPosition || attributes.trajectory.positions[attributes.end]
          });
        },
        textOffsetX:     10,
        textOffsetY:     10,
        textAlign:       "left",
        textBaseline:    "top",
        fontColor:       "#0247FE",
        textFont:        "bold 8px tahoma,arial,verdana,sans-serif",
        textStrokeColor: "rgba(255,255,255,1)",
        textStrokeWidth: 2
      },
      "lastPointLabel":             {
        label:           function (feature, attributes) {
          return THM_TRAJECTORY_STYLE_HELPER.getLabel({
            model:    this.definitions,
            isLast:   true,
            position: attributes.estimatedPosition || attributes.trajectory.positions[attributes.end]
          });
        },
        textOffsetX:     10,
        textOffsetY:     10,
        textAlign:       "left",
        textBaseline:    "top",
        fontColor:       "#0247FE",
        textFont:        "bold 8px tahoma,arial,verdana,sans-serif",
        textStrokeColor: "rgba(255,255,255,1)",
        textStrokeWidth: 2
      }
    };

    me._super();
  }
});

ThmBourbonStyleEngine.INSTANCE = new ThmBourbonStyleEngine();

export default ThmBourbonStyleEngine;
